import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdownWithHtml from "react-markdown";

import logo_mono from "../img/man.png";
import phone from "../img/ico/phone.svg";
import { DifficultyIcon, GroupIcon } from "../components/Icon";
import FixedDeptRoll from "../components/FixedDepartureRoll";
import hero2 from "../img/hero-3.jpg";
import { addDays, format } from "date-fns";
import IndvDeptRoll from "../components/IndividualDepartureRoll";
import CtaWidget from "../components/cta-widget/CtaWidget";

import stamp_2 from "../img/stamp-2.png";

import { Element, Link as ScrollLink } from "react-scroll";

import contour from "../img/contour.png";

export const FixedDepartureTemplate = ({
  item_id,
  description,
  title,
  helmet,
  itinerary,
  image,
  group_size,
  price,
  duration,
  difficulty,
  brochure,
  discount,
  slab,
}) => {
  let $image = getImage(image);

  const discounted_price = discount ? price - price * (discount / 100) : price;

  const [isMobile, setIsMobile] = React.useState(false);

  const getDates = (date) => {
    // extract day from '4th Aug 23'
    const day = date.split(" ")[0].replace(/\D/g, "");
    // extract month from '4th Aug 23'
    const month = date.split(" ")[1];
    // extract year from '4th Aug 23'
    const year = date.split(" ")[2];

    // make a date object from the extracted values
    const dateObj = new Date(`${month} ${day}, ${year}`);

    return `${date} - ${format(
      addDays(new Date(dateObj), duration),
      "do MMM yy"
    )}`;
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      }
    }
  }, []);

  const prepareLink = (brochure) => {
    return brochure.startsWith("http") || brochure.startsWith("https")
      ? brochure
      : `https://${brochure}`;
  };

  return (
    <>
      {helmet || ""}
      <section className="hero-section itinary-section">
        <div className="hero-img">
          <GatsbyImage image={$image} alt="itinary image" />
        </div>
        <div className="hero-wrapper itinary-wrapper">
          <div className="hero-header">
            <div className="hero-enquire">
              <a href="tel:+918076560633">
                TALK TO OUR EXPERTS
                <div className="mail">
                  <img src={phone} alt="phone" />
                </div>
                +91-80765-60633
              </a>
            </div>
          </div>
          <div className="hero-ctr">
            <h1 className="mt-2">{title}</h1>
            <ScrollLink to="enquire" spy={true} smooth={true} duration={1200}>
              <button className="btn btn-light-outline">Enquire Now!</button>
            </ScrollLink>
          </div>
        </div>
      </section>
      <Element name="enquire">
        <section className="itinary-about-section">
          <div className="contour-lines">
            <img src={contour} alt="contour" />
          </div>
          <div className="container">
            <div className="itinary-about-wrapper">
              <div className="overview">
                <p className="title">Overview</p>
                <div className="features-wrap">
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="ri-group-line"></i>
                    </div>
                    <div className="feature-text">
                      <p className="feature-title">Group Size</p>
                      <p className="feature-desc">{group_size}</p>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="ri-equalizer-line"></i>
                    </div>
                    <div className="feature-text">
                      <p className="feature-title">Difficulty</p>
                      <p className="feature-desc">{difficulty}</p>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="feature-icon">
                      <i className="ri-time-line"></i>
                    </div>
                    <div className="feature-text">
                      <p className="feature-title">Duration</p>
                      <p className="feature-desc">{duration} Days</p>
                    </div>
                  </div>
                  {discount ? (
                    <div className="feature">
                      <div className="feature-icon">
                        <i className="ri-price-tag-3-line"></i>
                      </div>
                      <div className="feature-text">
                        <p className="feature-title">Discount</p>
                        <p className="feature-desc">{discount}%</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <p className="desc">{description}</p>

                <div className="price-wrap">
                  {discount ? <p className="acc-pp">₹{price}</p> : <></>}
                  <p className="dis-pp">₹{discounted_price}</p>
                  <small>per person</small>
                </div>

                <div className="slab-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Slots</th>
                        <th>Availability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slab &&
                        slab.map((item, index) => (
                          <tr key={index}>
                            <td className="date">
                              {getDates(item.starting_date)}
                            </td>
                            <td
                              className={
                                item.remaining_seats == 0
                                  ? "sold-out"
                                  : "available"
                              }
                            >
                              {item.remaining_seats == 0
                                ? "Sold Out"
                                : "Available"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <div className="cta-wrap">
                  <a
                    className="btn btn-black-outline"
                    href={prepareLink(brochure)}
                    target="_blank"
                    referrerPolicy=""
                  >
                    Download brochure
                  </a>
                  <Link to="/enquire-now" className={"btn btn-black-outline"}>
                    Enquire Now
                  </Link>
                </div>
              </div>
              {!isMobile ? (
                <div className="itineary-wrapper">
                  {itinerary.map((item, index) => {
                    return index % 2 == 0 ? (
                      <div className="row itineary-card" key={index}>
                        <div
                          className={
                            "col-lg-7 col-md-7 col-xm-12 col-xs-12 desc-col desc-col-odd" +
                            `${index == 0 ? " first-col" : ""}`
                          }
                        >
                          <div className="desc-ctr">
                            <h2>{item.title}</h2>
                            <div className="markdown-content">
                              <ReactMarkdownWithHtml
                                children={item.description}
                                allowDangerousHtml
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            "col-lg-5 col-md-5 col-xm-12 col-xs-12 img-col" +
                            `${index == 0 ? " first-col" : ""}`
                          }
                        >
                          <GatsbyImage
                            image={item.image.childImageSharp.gatsbyImageData}
                            alt="itinary image"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row itineary-card" key={index}>
                        <div className="col-lg-5 col-md-5 col-xm-12 col-xs-12 img-col">
                          <GatsbyImage
                            image={item.image.childImageSharp.gatsbyImageData}
                            alt="itinary image"
                          />
                        </div>
                        <div className="col-lg-7 col-md-7 col-xm-12 col-xs-12 desc-col desc-col-even">
                          <div className="desc-ctr">
                            <h2>{item.title}</h2>
                            <div className="markdown-content">
                              <ReactMarkdownWithHtml
                                children={item.description}
                                allowDangerousHtml
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="itineary-wrapper">
                  {itinerary.map((item, index) => {
                    return (
                      <div className="row itineary-card" key={index}>
                        <div
                          className={
                            "col-lg-7 col-md-7 col-xm-12 col-xs-12 desc-col desc-col-odd"
                          }
                        >
                          <div className="desc-ctr">
                            <h2>{item.title}</h2>
                            <div className="markdown-content">
                              <ReactMarkdownWithHtml
                                children={item.description}
                                allowDangerousHtml
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            "col-lg-5 col-md-5 col-xm-12 col-xs-12 img-col" +
                            `${
                              index == itinerary.length - 1 ? " last-img" : ""
                            }`
                          }
                        >
                          <GatsbyImage
                            image={item.image.childImageSharp.gatsbyImageData}
                            alt="itinary image"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
      </Element>
      <FixedDeptRoll item_id={item_id} />
      <section className="about-brief-section less-padding">
        <div className="container">
          <div className="about-brief-wrapper">
            <CtaWidget
              card_type="light-green"
              image={hero2}
              title="We got your Back!"
              desc="We are here to help you plan your next adventure. Please
              fill out the form below and we will get in touch with you
              shortly."
              ctaTitle="Call Us"
              stamp_img={stamp_2}
              stamp_placement="outer"
              ctaLink="/enquire-now"
            />
          </div>
        </div>
      </section>
      <IndvDeptRoll />
    </>
  );
};

FixedDepartureTemplate.propTypes = {
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  itinerary: PropTypes.array,
  image: PropTypes.object,
  group_size: PropTypes.number,
  price: PropTypes.number,
  duration: PropTypes.number,
  difficulty: PropTypes.string,
  brochure: PropTypes.string,
  discount: PropTypes.number,
  slab: PropTypes.array,
  item_id: PropTypes.string,
};

const FixedDeparture = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FixedDepartureTemplate
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Group Departure - Charaeveti">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        itinerary={post.frontmatter.itinerary}
        image={post.frontmatter.image}
        group_size={post.frontmatter.group_size}
        price={post.frontmatter.price}
        duration={post.frontmatter.duration}
        difficulty={post.frontmatter.difficulty}
        brochure={post.frontmatter.brochure}
        discount={post.frontmatter.discount}
        slab={post.frontmatter.slab}
        item_id={post.id}
      />
    </Layout>
  );
};

FixedDeparture.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default FixedDeparture;

export const pageQuery = graphql`
  query FixedDepartureByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        group_size
        price
        difficulty
        brochure
        discount
        duration
        image {
          childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        slab {
          remaining_seats
          starting_date
        }
        itinerary {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(formats: [WEBP])
            }
          }
        }
      }
    }
  }
`;
